.rewardInPercent {
    > span {
        width: max-content;
        position: relative;

        &:hover {
            cursor: pointer;
            font-weight: bold;

            &:after {
                content: "✏️";
                position: absolute;
                right: -20px;
                top: 0;
                font-size: 0.8em;
            }
        }
    }
}
